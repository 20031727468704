// grabbed some from https://github.com/xsolla/currency-format/blob/master/currency-format.json
const currencyMap = {
    "USD": {
        "name": "US Dollar",
        "fractionSize": 2,
        "symbol": {
          "grapheme": "$",
          "template": "$1",
          "rtl": false
        },
        "uniqSymbol": {
          "grapheme": "$",
          "template": "$1",
          "rtl": false
        }
    },
    "JPY": {
      "name": "Yen",
      "fractionSize": 0,
      "symbol": {
        "grapheme": "¥",
        "template": "$1",
        "rtl": false
      },
      "uniqSymbol": {
        "grapheme": "¥",
        "template": "$1",
        "rtl": false
      }
    },
    "MXN": {
      "name": "Mexican Peso",
      "fractionSize": 2,
      "symbol": {
        "grapheme": "$",
        "template": "$1",
        "rtl": false
      },
      "uniqSymbol": null
    },
    "CNY": {
      "name": "Yuan Renminbi",
      "fractionSize": 2,
      "symbol": {
        "grapheme": "元",
        "template": "1 $",
        "rtl": false
      },
      "uniqSymbol": {
        "grapheme": "元",
        "template": "1 $",
        "rtl": false
      }
    }
}